import {
  isString as _isString,
  startsWith as _startsWith,
  has as _has,
} from 'lodash';

class AppHelper {
  static getStatusCode(e) {
    let statusCode = 500;
    if (_has(e, 'response') && _has(e.response, 'status')) {
      const { status } = e.response;
      statusCode = status;
    }
    return statusCode;
  }

  static getError(error) {
    const defaultMessage = 'Unknown error';

    let message = _has(error, 'response.data') && _isString(error.response.data?.errors?.length) ? error.response.data?.errors[0] : defaultMessage;

    if (message === defaultMessage) {
      message = _has(error, 'response.data.errors.email') ? error.response.data.errors.email[0] : defaultMessage;
    }

    if (message === defaultMessage) {
      message = _has(error, 'response.data.errors.fein') ? error.response.data.errors.fein[0] : defaultMessage;
    }

    if (message === defaultMessage) {
      message = _has(error, 'response.data.errors.socialNumber') ? error.response.data.errors.socialNumber[0] : defaultMessage;
    }
    
    if (message === defaultMessage) {
      message = _has(error, 'response.data.message') ? error.response.data.message : defaultMessage;
    }

    if (message === defaultMessage) {
      message = _has(error, 'response.data.msg') ? error.response.data['msg'] : defaultMessage;
    }

    if (message === defaultMessage) {
      message = _has(error, 'message') ? error.message : defaultMessage;
    }
    return message;
  }

  static checkError(e, snackbarUI = null) {
    //const message = this.customizeMessage(this.getError(e));
    const code = this.getStatusCode(e);
    const error = this.getmessageError(e?.response?.data)
    const message = this.customizeMessage(error, code)
    if (!snackbarUI) {
      return { message, code };
    }
    snackbarUI.current.open(message);
  }

  static getmessageError(e) {
    let message
    if(e?.error_description === 'The provided email or password is incorrect.') return message = e?.error_description
    if(e?.error) return message = e?.error
    if(e?.errors?.length) return message = e?.errors[0]
    if(e?.item_errors?.length){
      let text = e?.item_errors[0]
      text = `${text?.errors[0]}, ${text?.item?.email}`
      return message = text
    }
    return message
  }
  
  static showCustomMessage(message, snackbarUI) {
    snackbarUI.current.open(message);
  }

  static throwException(message) {
    throw new Error(message);
  }

  static customizeMessage(message, code) {
    let customMessage = message;
    if (code === 500 || code === 403) {
      customMessage = 'At this time we are having problems making the request, try again later.';
    }
    if (code === 404) {
      customMessage = 'Not found';
    }
    if (this.startsWithText(customMessage, 'Error:')) {
      customMessage = 'Chart not found';
    }

    if (message === 'unauthorized') {
      customMessage = 'Your session has expired';
    }

    if (customMessage?.length > 100) {
      customMessage = 'Server Error';
    }

    return customMessage;
  }

  static startsWithText(str, searchValue) {
    if (_isString(str) && _isString(searchValue)) {
      return _startsWith(str.toLowerCase(), searchValue.toLowerCase());
    } else {
      return false;
    }
  }
}

export default AppHelper;
