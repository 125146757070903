import AppServiceClient from './AppServiceClient';

class UserServiceNewApi extends AppServiceClient {

  getUserById(id){
    const URL = `/auth/v1/user/${id}`
    return this.http.get(URL)
  }

  getAllUsers(page, size, filters){
    let filterString = "";
    if (filters?.fullName) filterString = `${filterString}FullName=${filters?.fullName}&`;
    if (filterString === "&") filterString = "";
    else filterString = filterString.substring(0, filterString?.length);
    return this.http.get(`/auth/v1/user?${filterString}PageNumber=${page}&PageSize=${size}`);
  }
  
  addUser(data) {
    return this.http.post('/auth/v1/user', data);
  }

  editUser(id, data){
    return this.http.put(`/auth/v1/user/${id}`, data);
  }
  
  activateUser(id) {
    return this.http.put(`/auth/v1/user/${id}/reactivate`);
  }
  
  inActivateUser(id) {
    return this.http.delete(`/auth/v1/user/${id}`);
  }

  getRoles(){
    return this.http.get('/auth/v1/role');
  }

}

export default UserServiceNewApi;
