import AppServiceNewApi from "./AppServiceNewApi";

class OrganizationServiceNewApi extends AppServiceNewApi{
    constructor() {
        super();
        this.path = '/api/internal/v1/organization';
    }
    
    getOrganization(){
        return this.http.get(`${this.path}`);
    }
    
    getOrganizationById(id){
        return this.http.get(`${this.path}/${id}`);
    }

    createOrganization(body){
        return this.http.post(`${this.path}`, body);
    }
    
    addLogo(id, body){
        return this.http.post(`${this.path}/${id}`, body);
    }
    
    updateOrganization(id, body){
        return this.http.put(`${this.path}/${id}`, body);
    }

}
export default OrganizationServiceNewApi;