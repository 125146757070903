// @vendors
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

// @context
import { useUI } from "../../app/context/ui";
import { addUser } from "../../redux/actions/user";
import store from "../../redux/store";
import { types } from "../../redux/types";

// @asstes
import { LoginStyles } from "../../assets/css/auth-style";
import logo from "../../assets/images/logoDashboardPublic.svg";

// @components material
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Visibility,
  VisibilityOff,
} from "../../components/shared/MaterialUI";

// @helpers
import AppHelper from "../../helpers/AppHelper";
import { useQuery } from "../../helpers/useQuery";
import { authForSuperAdmin, newAuthToken} from "../../helpers/GetTokenNewApi";
import { getOrganizations, getOrganizationWithoutToken } from "../../helpers/FunctionsUtils";
import { LISTROLES } from "../../helpers/ListRoles";

// @services
import CompanyService from "../../services/newApi/CompanyServiceNewApi";

// @constants
import { ROUTENAME } from "../../navigation/RouteName";

const LoginPage = (props) => {
  const query = useQuery();
  const history = useHistory();
  const state = store.getState();
  const accessToken = state?.user?.accessToken;
  const { blockUI, snackbarUI } = useUI();
  const styles = LoginStyles();
  const companyService = new CompanyService();
  const dispatch = useDispatch();
  const [currentLogo, setCurrentLogo] = useState();

  // if error code 401
  if (query.get("et")) {
    props.dispatch({ type: "LOGOUT" });
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (accessToken) {
    history.push(ROUTENAME.dashboard);
  }

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid e-mail")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Minimum 8 characters")
      .required("Password is required"),
  });

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      const auth = await newAuthToken(values, snackbarUI);
      companyService.setAccessToken(auth?.data?.access_token);
      if(auth?.data?.access_token)successLogin(auth);
      blockUI.current.open(false);
    } catch (e) {
      hasErrorLogin(e);
    }
  };

  const successLogin = async (auth) => {
    const r2 = await companyService.me();
    let payload = infoUser(r2, auth);
    props.dispatch(addUser(payload));
    isAdmin(r2);
    await getListOrganization();
    blockUI.current.open(false);
    history.push(ROUTENAME.dashboard);
  }

  // get list of organization to show select field on the header
  const getListOrganization = async () => {
    const response = await getOrganizations(snackbarUI, blockUI);
    const byDefault = response?.data?.data[0];
    dispatch({
      type: types.ORGANIZATIONS_LIST,
      data: response?.data?.data,
      organizationSelected: byDefault
    });
  }

  const hasErrorLogin = (e) => {
    blockUI.current.open(false);
      if (
        e?.response?.data?.message === "The provided credentials are incorrect."
      ) {
        AppHelper.checkError(e, snackbarUI);
      } else {
        history.push({
          pathname: "/error",
          state: {
            message: e?.response?.data?.message,
          },
        });
      }
  }

  // when the user has the role super admin
  const isAdmin = (r2) => {
    const role = r2?.data?.role;
    if (role && role === LISTROLES.superAdmin) {
      authForSuperAdmin(props.dispatch, snackbarUI, true);
    }
  };

  const infoUser = (r2, auth) => {
    let payload = {
      ...r2?.data,
      accessToken: auth?.data?.access_token,
      tokenNewApi: auth?.data,
      expiryTime: auth?.expiryTime,
      isAdmin: r2?.data?.role === LISTROLES.superAdmin ? true : false,
      hasNewToken: auth?.data?.access_token ? true : false,
    };
    return payload;
  };

  //call list of organization without authentication to compare current url and show the logo
  const logoOrganization = async () => {
    const currentLogo = await getOrganizationWithoutToken(blockUI, snackbarUI);
    setCurrentLogo(currentLogo ? currentLogo : logo)
    setTimeout(() => {
      blockUI.current.open(false);
    }, 1000);
  }

  useEffect(() => {
    logoOrganization();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.title = `RockerBox - ${props.title}`;
  });

  return (
    <>
      <Link href="/" sx={styles.logo}>
        {currentLogo &&
          <img
            src={currentLogo}
            alt="dashboard public"
            className="loginImage"
            style={{
              objectFit: 'fit',
              width: '300px',
              height: '100px',
              borderRadius: '3px'
            }}
          />
        }
      </Link>

      <Container component="main" maxWidth="100%" sx={styles.loginContainer}>
        <Typography variant="h1">Welcome</Typography>
        <Typography variant="h3">
          Sign up and start managing your candidates
        </Typography>
        <Box component="div" sx={styles.loginForm}>
          <Box component="div" sx={styles.formMain}>
            <Formik
              initialValues={{
                password: "",
                showPassword: false,
                email: "",
              }}
              onSubmit={(values) => {
                onSubmit(values).then(() => {});
              }}
              validationSchema={LoginSchema}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                } = props;
                return (
                  <Form className="form">
                    <Box sx={{ mb: 4 }}>
                      <label htmlFor="email" className="inputLabels">
                        Email:
                      </label>
                      <TextField
                        required
                        fullWidth
                        name="email"
                        id="email"
                        autoComplete="email"
                        label="Type your e-mail"
                        value={values.email}
                        type="email"
                        variant="outlined"
                        helperText={
                          errors.email && touched.email ? errors.email : ""
                        }
                        error={errors.email && touched.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="example@gmail.com"
                        InputLabelProps={{ shrink: true }}
                        sx={styles.input}
                      />
                    </Box>
                    <Box sx={{ mb: 4 }}>
                      <label
                        htmlFor="standard-adornment-password"
                        className="inputLabels"
                      >
                        Password:
                      </label>
                      <Box sx={{ position: "relative" }}>
                        <TextField
                          required
                          fullWidth
                          label="Type your password here"
                          name="password"
                          id="standard-adornment-password"
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Type you password here"
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : ""
                          }
                          error={errors.password && touched.password}
                          InputLabelProps={{ shrink: true }}
                          sx={styles.input}
                        />
                        <InputAdornment position="end">
                          <IconButton
                            sx={styles.icoShowPassword}
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setFieldValue(
                                "showPassword",
                                !values.showPassword
                              );
                            }}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </Box>
                    </Box>
                    <Box container sx={styles.gridForgotPassword}>
                      <Link
                        href="/recover-password"
                        variant="body2"
                        sx={styles.link}
                      >
                        Forgot your password?
                      </Link>
                    </Box>
                    <Box sx={styles.wrapperBtnSubmit}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={styles.btnSubmit}
                      >
                        Sign In
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default connect(null)(LoginPage);