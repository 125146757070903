/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';

// @services
import UserServiceNewApi from '../../../services/AppServiceClient/UserService';
import OrganizationServiceNewApi from '../../../services/newApi/OrganizationService';
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi';

// @context
import { useUI } from '../../../app/context/ui';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { MenuItemProps, permissionsForOrganization } from '../../../helpers/FunctionsUtils';
import { LISTROLES } from '../../../helpers/ListRoles';

// @assets
import { StyleAPiClientDialog } from '../../ApiClient/StyleApiClient';
import { UserManagementStyle } from '../../../assets/css/employeeTable-style';
import { CreateEmployeeStyles } from '../../../assets/css/createEmployee-style';
import { CreateEmployerStyles } from '../../../assets/css/createEmployer-style';

// @components material
import {
  Visibility,
  VisibilityOff,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
  Chip,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '../../../components/shared/MaterialUI';

// @components
import { AppSelect, AppTextField } from '../../../components/forms';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

const AddUser = (props) => {
  const { id } = props;
  const history = useHistory();
  const userService = new UserServiceNewApi();
  const organizationService = new OrganizationServiceNewApi();
  const companyService = new CompanyServiceNewApi();
  const { blockUI, snackbarUI } = useUI();
  const style = UserManagementStyle();
  const styleForm = CreateEmployeeStyles();
  const userStyle = CreateEmployerStyles();
  const styles = StyleAPiClientDialog()
  const [showPassword, setShowPassword] = useState(false);
  const [listRoles, setListRoles] = useState([]);
  const [listOrganizations, setListOrganizations] = useState([]);
  const [listCompanies, setListCompanies] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState([])
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [showCompany, setShowCompany] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    organizationIds: '',
    companyIds: '',
    hasAccessToAllOrganizatons: false,
    //hasAccessToAllCompanies: false,
  }
  const [formValues, setFormValues] = useState(initialValues)

  const validationEmployee = Yup.object({
      firstName: Yup.string('Enter your name')
        .required('Name is required')
        .nullable()
        .typeError(`Name can't be empty`),
      lastName: Yup.string('Enter your last Name')
        .required('last Name is required')
        .nullable()
        .typeError(`last Name can't be empty`),
      email: Yup.string('Enter the email')
        .email('Invalid email')
        .max(50, 'Max. 50 characters')
        .required('Email is required')
        .typeError(`Email can't be empty`),
      role: Yup.string('Enter your role')
        .nullable()
        .required('Role is required')
        .typeError(`Role can't be empty`),
      password: !id
        ? Yup.string('Enter your password')
            .min(8, 'Minimum 8 characters')
            .matches(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/,
              'Must have at least one Uppercase, one symbol and one number'
            )
            .required('Password is required')
        : Yup.string()
            .nullable()
            .notRequired(),
      organizationIds: showOrganization === false
        ? Yup.string().nullable().notRequired()
        : Yup.string().required('Organization is required'),
      companyIds: showCompany === false
        ? Yup.string().nullable().notRequired()
        : Yup.string().required('Company is required'),
      hasAccessToAllOrganizatons: Yup.bool().nullable().notRequired(),
      //hasAccessToAllCompanies: Yup.bool().nullable().notRequired(),
  });

  const getUser = async () => {
    try {
      blockUI.current?.open(true);
      const response = await userService.getUserById(id);
      preloadFields(response?.data);
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const preloadFields = (data) => {
    setFormValues({
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      password: data?.password,
      role: data?.role,
      organizationIds: data?.organizations?.length ? 'value' : '',
      companyIds: data?.companies?.length ? 'value' : '',
      hasAccessToAllOrganizatons: data?.hasAccessToAllOrganizatons,
      //hasAccessToAllCompanies: false,
    });
   if(data?.role !== LISTROLES.superAdmin)preloadOrganization(data);
   if(data?.companies?.length && checkRoleForCompany(data?.role))preloadCompany(data);
    setTimeout(() => {
      blockUI.current.open(false);
    }, 1000);
  }
  
  const preloadOrganization = (data) =>{
    if(data?.hasAccessToAllOrganizatons)return;
    setSelectedOrg(data?.organizations);
    setShowOrganization(true);
  }
  
  const preloadCompany = (data) =>{
    getCompanies(data?.organizations[0]?.id);
    setSelectedCompanies(data?.companies);
    setShowCompany(true);
  }

  useEffect(() => {
    if(id)getUser();
  }, [id])

  const getAllRoles = async () => {
    try {
      blockUI.current?.open(true);
      const response = await userService.getRoles();
      const newList = response?.data?.map((item) => {return {id: item, name: item?.replace('_', ' ')}});
      setListRoles(newList)
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const getCompanies = async (organizationId) => {
    try {
      blockUI.current?.open(true);
      const response = await companyService.getCompanies({page:1, size:1000}, organizationId);
      setListCompanies(response?.data?.data)
      blockUI.current?.open(false);
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const getOrganizations = async () => {
    try {
      blockUI.current?.open(true);
      const response = await organizationService.getOrganization();
      setListOrganizations(response?.data?.data);
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  useEffect(() => {
    getAllRoles();
    getOrganizations();
  }, [])

  const onSubmit = (values) => {
    const OBJ = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      role: values?.role,
      password: values.password,
      organizationIds: returnIdsSelected(values?.role, selectedOrg),
      companyIds: returnIdsSelected(values?.role, selectedCompanies),
      hasAccessToAllOrganizatons: hasAccessToAllOrganizatons(values),
      hasAccessToAllCompanies: hasAccessToAllCompanies(values),
    };
    return handleSubmitData(OBJ);
  }

  const hasAccessToAllOrganizatons = (values) => {
    if(values.role === LISTROLES.superAdmin)return true;
    if(permissionsForOrganization(values.role))return values.hasAccessToAllOrganizatons;
    return false;
  }
  
  const hasAccessToAllCompanies = (values) => {
    if(values.role === LISTROLES.superAdmin || permissionsForOrganization(values.role))return true;
    return false;
  }

  const returnIdsSelected = (role, currentList) => {
    if(role === LISTROLES.superAdmin)return [];
    if(currentList?.length){
      const list = currentList?.map((org) => org?.id)
      return list;
    }
    return [];
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmitData = async (data) => {
    try {
      blockUI.current?.open(true);
      id ? await userService.editUser(id, data) : await userService.addUser(data);
      blockUI.current?.open(false);
      history.push(ROUTENAME.userManagement)
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  //reset some fields and show company list or organization list
  const handleChangeClick = (e, setFieldValue) => {
    const value = e.target.dataset.value;
    if(!value)return
    resetFields(setFieldValue);
    if(value === LISTROLES.superAdmin){
      setShowOrganization(false)
      setShowCompany(false)
      return
    }
    if(permissionsForOrganization(value)){
      setShowOrganization(true);
      setShowCompany(false);
      return
    }
    if(checkRoleForCompany(value)){
      setShowOrganization(true)
      setShowCompany(true)
      return;
    }
  }

  //if organization check is marked hiden de organization list
  const handleChangeOrganization = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setSelectedOrg([]);
    setFieldValue('organizationIds', '')
    if(isChecked)return setShowOrganization(false);
    return setShowOrganization(true);
  }

  const resetFields = (setFieldValue) => {
    setFieldValue('hasAccessToAllOrganizatons', false);
    setFieldValue('hasAccessToAllCompanies', false);
    setFieldValue('organizationIds', '')
    setFieldValue('companyIds', '')
    setSelectedOrg([]);
    setSelectedCompanies([]);
  }

  const handleChangeOrg = (event, type) => {
    const value = event?.target.value;
    let typeValue = typeof value === 'string' ? value?.split(',') : value;
    if(value?.id){
      typeValue = [value];
      setSelectedCompanies([]);
      if(type === 1)getCompanies(value?.id);
    }
    type === 1 ? setSelectedOrg(typeValue) : setSelectedCompanies(typeValue);
  };

  const checkRoleForCompany = (role) => {
    if(role === LISTROLES.employerManager ||
      role === LISTROLES.employerReader ||
      role === LISTROLES.employerReporter
    )return true;
    return false;
  }
  
  useEffect(() => {
    if(!id && listOrganizations?.length && listRoles?.length)blockUI.current.open(false);
  }, [id, listOrganizations, listRoles])

  return (
    <Grid sx={style.container}>
      <Grid sx={style.containerBody}>
        <Typography sx={style.title}>{id ? 'Edit user' : 'Create user'}</Typography>
        
        <Grid >
          <Formik
            initialValues={formValues}
            validationSchema={validationEmployee}
            onSubmit={(values) => { onSubmit(values) }}
            enableReinitialize={true}
          >
            {(props) => {
              const {
                touched,
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                values,
              } = props;
              return (
                  <Form autoComplete="off" className="formCreateUser">
                      <Box sx={{...styleForm.inputsCtn, gridGap: '10px',}}>
                          <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                  <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                      <p>First Name: <span style={{color: "red"}}>*</span> </p>
                                      <AppTextField
                                          sx={styleForm.input}
                                          label="Type your first name here"
                                          variant="outlined"
                                          id="firstName"
                                          name="firstName"
                                      />
                                  </Box>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                  <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                      <p>Last Name: <span style={{color: "red"}}>*</span> </p>
                                      <AppTextField
                                          sx={styleForm.input}
                                          label="Type your last name here"
                                          variant="outlined"
                                          id="lastName"
                                          name="lastName"
                                        />
                                  </Box>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                  <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                      <p>Email: <span style={{color: "red"}}>*</span> </p>
                                      <AppTextField
                                          sx={styleForm.input}
                                          label="Type your email here"
                                          variant="outlined"
                                          id="email"
                                          name="email"
                                      />
                                  </Box>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Password: <span style={{color: "red"}}>*</span> </p>
                                    <Box sx={{ position: 'relative'}}>
                                        <TextField
                                            label="Password"
                                            variant="outlined"
                                            autoComplete="new-password"
                                            id="password"
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleBlur}
                                            error={touched.password && Boolean(errors.password)}
                                            sx={{...styleForm.input }}
                                        />

                                        <IconButton
                                            tabIndex={-1}
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((prev) => !prev)}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            sx={userStyle.showPassword}
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>

                                        {errors.password && touched.password && (
                                            <Typography sx={userStyle.stateError}>{errors.password}</Typography>
                                        )}
                                    </Box>
                                </Box>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                  <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                      <p>Role: <span style={{color: "red"}}>*</span> </p>
                                      <AppSelect
                                        id="role"
                                        name="role"
                                        state={true}
                                        onClick={(e) => {handleChangeClick(e, setFieldValue)}}
                                        data={listRoles}
                                        value={values.role}
                                        autoFocus={true}
                                        fullWidth
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {border: '1px solid #101010'}
                                          },
                                        }}
                                        placeholder={'-- Select --'}
                                      />
                                  </Box>
                              </Grid>

                              {showOrganization &&
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                        <p>Organization: <span style={{color: "red"}}>*</span> </p>
                                        <Select
                                          id="demo-simple-select"
                                          name='organizationIds'
                                          multiple={checkRoleForCompany(values?.role) ? false : true}
                                          value={selectedOrg}
                                          renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {selected?.map((value) => (
                                                <Chip key={value?.id} label={value?.name} />
                                              ))}
                                            </Box>
                                          )}
                                          sx={{...styles.fieldsInput, height: '56px'}}
                                          onChange={(e) => {
                                            handleChangeOrg(e, 1)
                                            setFieldValue('organizationIds', 'value');
                                            setFieldValue('companyIds', '');
                                          }}
                                          MenuProps={MenuItemProps}
                                          error={!showOrganization || selectedOrg?.length ? null : touched.organizationIds && Boolean(errors.organizationIds)}
                                        >
                                          {listOrganizations?.map((item, index) => (
                                            <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                          ))}
                                        </Select>
                                        <FormHelperText style={{color: '#D32F2F', fontSize: '14px', marginLeft: '15px' }}>
                                        {!showOrganization || selectedOrg?.length ? null : touched.organizationIds && errors.organizationIds}
                                        </FormHelperText>
                                    </Box>
                                  </FormControl>
                                </Grid>
                              }
                             
                              {showCompany &&
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                        <p>Companies: <span style={{color: "red"}}>*</span> </p>
                                        <Select
                                          id="demo-simple-select"
                                          name='companyIds'
                                          //multiple={false}
                                          value={selectedCompanies}
                                          renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {selected?.map((value) => (
                                                <Chip key={value?.id} label={value?.name} />
                                              ))}
                                            </Box>
                                          )}
                                          sx={{...styles.fieldsInput, height: '56px'}}
                                          onChange={(e) => {
                                            handleChangeOrg(e, 2)
                                            setFieldValue('companyIds', 'value')
                                          }}
                                          MenuProps={MenuItemProps}
                                          error={!showCompany || selectedCompanies?.length ? null : touched.companyIds && Boolean(errors.companyIds)}
                                        >
                                          {listCompanies?.map((item, index) => (
                                            <MenuItem value={item} key={index}>{item?.name}</MenuItem>
                                          ))}
                                        </Select>
                                        <FormHelperText style={{color: '#D32F2F', fontSize: '14px', marginLeft: '15px' }}>
                                        {!showCompany || selectedCompanies?.length ? null : touched.companyIds && errors.companyIds}
                                        </FormHelperText>
                                    </Box>
                                  </FormControl>
                                </Grid>
                              }

                              {values.role && permissionsForOrganization(values?.role) &&
                                <Grid container sx={{marginTop: '20px'}}>
                                  {permissionsForOrganization(values?.role) &&
                                    <Grid item xs={12} md={6}>
                                      <Box sx={{...styleForm.boxItem}} >
                                        <FormControlLabel
                                          checked={values.hasAccessToAllOrganizatons}
                                          disabled={false}
                                          name='hasAccessToAllOrganizatons'
                                          control={
                                            <Checkbox
                                              sx={{margin: '0px'}}
                                              color="success"
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleChangeOrganization(e, setFieldValue);
                                              }}
                                            />
                                          }
                                          label='Has access to all organizations'
                                          labelPlacement="end"
                                          sx={userStyle.checkbox}
                                        />
                                      </Box>
                                    </Grid>
                                  }                                                 
                                  
                                  {/* {checkAllCompanies(values?.role) &&
                                    <Grid item xs={12} md={6}>
                                      <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                        <FormControlLabel
                                          checked={values.hasAccessToAllCompanies}
                                          disabled={false}
                                          name='hasAccessToAllCompanies'
                                          control={
                                            <Checkbox
                                              sx={{margin: '0px'}}
                                              color="success"
                                              onChange={handleChange}
                                            />
                                          }
                                          label='Has access to all companies'
                                          labelPlacement="end"
                                          sx={userStyle.checkbox}
                                        />
                                      </Box>
                                    </Grid> 
                                  } */}                                                 
                                </Grid>
                              }
                          </Grid>
                      </Box>

                      <Box sx={styleForm.formButtonWrapper}>
                          <Button
                              color="inherit"
                              onClick={() => history.push(ROUTENAME.userManagement)}
                          >
                              Cancel
                          </Button>
                          <Button type="submit">{id ? 'Edit' : 'Create'}</Button>
                      </Box>
                  </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddUser